import React from "react";
import {graphql} from "gatsby";
import toH from "hast-to-hyperscript";
import styled from "@emotion/styled";
import Ui from "../components/Ui";
import colors from "../lib/colors";
import Toggler from "../components/Toggler";
import GatherNodes from "../components/GatherNodes";
import scrollTo from "../lib/scroll";
import Icon from "../components/Icon";
import DefaultLayout from "../components/DefaultLayout";
import Hero from "../components/bricks/Hero";

const renderAst = (node, mapping) => {
  const h = (name, props, children) => {
    return React.createElement(mapping[name] || name, props, children);
  };

  return toH(h, node);
};

const parseFaq = htmlAst => {
  const sections = [];
  let currentSection = null;
  let currentQuestion = null;
  htmlAst.children.forEach(firstLevelChild => {
    const {type, tagName, value} = firstLevelChild;
    if (type === "text" && !value.trim()) return;
    if (tagName === "h2") {
      currentSection = {
        heading: firstLevelChild,
        questions: [],
      };
      sections.push(currentSection);
    } else if (tagName === "h3") {
      currentQuestion = {heading: firstLevelChild, answer: []};
      currentSection.questions.push(currentQuestion);
    } else if (currentQuestion) {
      currentQuestion.answer.push(firstLevelChild);
    }
  });
  return sections;
};

const faqBreak = "@media(max-width: 600px)";

const ContentContainer = styled("div")({
  backgroundColor: colors.brightBg,
  color: colors.dark,
  padding: "4rem 2rem",
  [faqBreak]: {paddingLeft: "1rem", paddingRight: "1rem"},
});

const ContentInner = styled("div")({
  display: "flex",
  margin: "0 auto",
  maxWidth: "50rem",
  [faqBreak]: {
    flexDirection: "column",
  },
});

const NavContainer = styled("div")({
  marginRight: "5rem",
  marginTop: "0.5rem",
  flex: "none",
  [faqBreak]: {
    margin: "0 0 3rem",
  },
});
const NavInner = styled("div")({
  position: "sticky",
  top: "6rem",
  [faqBreak]: {display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap"},
});
const NavLink = styled(Ui.Link)({
  cursor: "pointer",
  fontWeight: "normal",
  display: "block",
  marginBottom: "1rem",
  fontSize: "0.8rem",
  [faqBreak]: {
    margin: "0 0.5rem 1rem",
    textAlign: "center",
    fontSize: "0.7rem",
  },
});

const Nav = ({sections, getNode}) => (
  <NavContainer>
    {renderAst(
      {
        type: "element",
        tagName: "NavInner",
        children: sections.map((s, i) => ({
          ...s.heading,
          tagName: "NavLink",
          properties: {onClick: () => scrollTo(getNode(i))},
        })),
      },
      {NavInner, NavLink}
    )}
  </NavContainer>
);

const QButtonContainer = styled("button")(({active}) => ({
  transitionProperty: "background-color, border-color",
  fontSize: "1rem",
  fontFamily: "inherit",
  position: "relative",
  border: `0.05rem solid ${colors.active}`,
  "& .arrow-as-border": {borderRightColor: colors.active},
  "& .inner-arrow": {borderRightColor: active ? colors.white : colors.brightBg},
  padding: "1rem 1.5rem",
  paddingRight: active ? "1rem" : undefined,
  backgroundColor: active ? colors.white : "transparent",
  cursor: "pointer",
  textAlign: "left",
  ":hover": {
    backgroundColor: colors.white,
    "& .inner-arrow": {borderRightColor: colors.white},
  },
  ":focus": {
    outline: "none",
    borderColor: colors.brand,
    "& .arrow-as-border": {borderRightColor: colors.brand},
  },
}));

const QuestionButton = ({onClick, children, active}) => (
  <QButtonContainer active={active} onClick={onClick}>
    <Ui.Row css={{alignItems: "baseline"}}>
      {children}
      {active && <Icon.Close css={{marginLeft: "2rem", color: colors.brand, fontSize: "0.7rem"}} />}
    </Ui.Row>
  </QButtonContainer>
);

const AnswerComp = styled("div")({
  position: "relative",
  lineHeight: 1.5,
  padding: "1rem 1.5rem",
  fontSize: "0.9rem",
  margin: "0.4rem 0 1.5rem",
  border: `0.05rem solid ${colors.borderGray}`,
  color: colors.fade(colors.dark, 0.8),
  " p:not(:last-child)": {marginBottom: "1rem"},
  " a": {color: colors.link, fontWeight: "bold"},
  " a:hover": {color: colors.linkHover},
});
const Answer = ({children}) => <AnswerComp>{children}</AnswerComp>;

const Question = ({question}) => (
  <Toggler>
    {(isActive, toggle) => (
      <Ui.Col css={{marginBottom: "0.7rem", alignItems: "flex-start"}}>
        {renderAst(
          {
            ...question.heading,
            tagName: "QuestionButton",
            properties: {onClick: toggle, active: isActive},
          },
          {QuestionButton}
        )}
        {isActive &&
          renderAst({type: "element", tagName: "Answer", children: question.answer}, {Answer})}
      </Ui.Col>
    )}
  </Toggler>
);

const SectionComp = styled("div")({":not(:last-child)": {marginBottom: "4rem"}});

const SectionHeading = styled(Ui.H2)({marginBottom: "1rem"});

const Section = React.forwardRef(({section}, ref) => (
  <SectionComp ref={ref}>
    {renderAst({...section.heading, tagName: "SectionHeading"}, {SectionHeading})}
    <div>
      {section.questions.map((q, i) => (
        <Question key={i} question={q} />
      ))}
    </div>
  </SectionComp>
));

const FaqTemplate = ({data: {markdownRemark: post, site}, location}) => {
  const sections = parseFaq(post.htmlAst);
  return (
    <DefaultLayout title="FAQ" location={location} description="">
      <Hero size="sm" title={post.frontmatter.title} />
      <GatherNodes>
        {(registerNode, getNode) => (
          <ContentContainer>
            <ContentInner>
              <Nav sections={sections} getNode={getNode} />
              <div>
                {sections.map((s, i) => (
                  <Section key={i} section={s} ref={registerNode(i)} />
                ))}
              </div>
            </ContentInner>
          </ContentContainer>
        )}
      </GatherNodes>
    </DefaultLayout>
  );
};

export default FaqTemplate;

export const query = graphql`
  query FaqContentBySlug($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      htmlAst
      frontmatter {
        title
      }
    }
  }
`;
