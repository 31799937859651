import React from "react";

export default class Toggler extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggle = () => this.setState({isActive: !this.state.isActive});
    this.handleToggle.on = () => this.setState({isActive: true});
    this.handleToggle.off = () => this.setState({isActive: false});

    this.state = {
      isActive: this.props.defaultActive || false,
    };
  }

  render() {
    const {isActive} = this.state;
    return this.props.children(isActive, this.handleToggle);
  }
}
