import React from "react";

export default class GatherNodes extends React.Component {
  _refFns = {};
  _refs = {};

  registerNode = name => {
    if (!this._refFns[name]) {
      this._refFns[name] = n => (this._refs[name] = n);
    }
    return this._refFns[name];
  };

  getNode = name => this._refs[name];

  render() {
    return this.props.children(this.registerNode, this.getNode);
  }
}
